"use client";
import React from "react";
import Link from "next/link";
import LanguageSelect from "../common/LanguageSelect";
// import CurrencySelect from "../common/CurrencySelect";
import SearchModal from "./SearchModal";
import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";

export default function MobileMenu() {
  const pathname = usePathname();
  const { t } = useTranslation();

  // Build the productsPages array using translation keys from your i18n JSON.
  const productsPages = [
    {
      heading: t("nav1.category1"), // "Workwear"
      links: [
        { href: "/workwear", text: t("nav1.subcategories.0.title1") }, // "Trousers"
        { href: "/workwear", text: t("nav1.subcategories.1.title2") }, // "Jackets"
        { href: "/workwear", text: t("nav1.subcategories.2.title3") }, // "Waistcoats"
        { href: "/workwear", text: t("nav1.subcategories.3.title4") }, // "Pullovers"
        { href: "/workwear", text: t("nav1.subcategories.4.title5") }, // "Shirts"
        { href: "/workwear", text: t("nav1.subcategories.6.title7") }, // "Aprons"
      ],
    },
    {
      heading: t("nav3.category3"), // "Hand/Arm Protection"
      links: [
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.0.title1"),
        }, // "Arm Protection"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.1.title2"),
        }, // "Antistatic Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.2.title3"),
        }, // "Chemical Protective Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.3.title4"),
        }, // "Mechanic's Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.6.title7"),
        }, // "Welding Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.7.title8"),
        }, // "Puncture Protection Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.8.title9"),
        }, // "Winter Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.9.title10"),
        }, // "Leather Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.10.title11"),
        }, // "Nitrile Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.11.title12"),
        }, // "Knitted Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.12.title13"),
        }, // "PVC Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.13.title14"),
        }, // "Cotton Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.14.title15"),
        }, // "Disposable Gloves"
      ],
    },
    {
      heading: t("nav4.category4"), // "Foot Protection"
      links: [
        { href: "/foot-protection", text: t("nav4.subcategories.0.title1") }, // "Clogs"
        { href: "/foot-protection", text: t("nav4.subcategories.1.title2") }, // "Low Shoes"
        { href: "/foot-protection", text: t("nav4.subcategories.2.title3") }, // "Sandals"
        { href: "/foot-protection", text: t("nav4.subcategories.3.title4") }, // "Slippers"
        { href: "/foot-protection", text: t("nav4.subcategories.4.title5") }, // "Boots"
        { href: "/foot-protection", text: t("nav4.subcategories.5.title6") }, // "Winter Boots"
      ],
    },
    {
      heading: t("nav5.category6"), // "Eye Protection"
      links: [
        { href: "/eye-protection", text: t("nav5.subcategories.2.title3") }, // "Ultraviolet Filter"
        { href: "/eye-protection", text: t("nav5.subcategories.3.title4") }, // "Sunglare Filter"
      ],
    },
    {
      heading: t("nav6.category7"), // "Head Protection"
      links: [
        { href: "/head-protection", text: t("nav6.subcategories.0.title1") }, // "Safety Helmets"
      ],
    },
    {
      heading: t("nav7.category8"), // "Kids Selection"
      links: [
        { href: "/kids-selection", text: t("nav7.subcategories.0.title1") }, // "Garments"
        { href: "/kids-selection", text: t("nav7.subcategories.1.title2") }, // "Hand Protection"
      ],
    },
  ];

  const isMenuActive = (menuItem) => {
    let active = false;
    if (menuItem.href?.includes("/")) {
      if (menuItem.href.split("/")[1] === pathname.split("/")[1]) {
        active = true;
      }
    }
    if (menuItem.links) {
      menuItem.links.forEach((elm2) => {
        if (elm2.href?.includes("/")) {
          if (elm2.href.split("/")[1] === pathname.split("/")[1]) {
            active = true;
          }
        }
        if (elm2.links) {
          elm2.links.forEach((elm3) => {
            if (elm3.href.split("/")[1] === pathname.split("/")[1]) {
              active = true;
            }
          });
        }
      });
    }
    return active;
  };

  return (
    <div className="offcanvas offcanvas-start canvas-mb" id="mobileMenu">
      <span
        className="icon-close icon-close-popup"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
      <div className="mb-canvas-content">
        <div className="mb-body">
          <ul className="nav-ul-mb" id="wrapper-menu-navigation">
            {productsPages.map((item, i) => {
              const itemId = `mobile-menu-category-${i}`;
              return (
                <li key={i} className="nav-mb-item">
                  <a
                    href={`#${itemId}`}
                    className={`collapsed mb-menu-link current ${
                      isMenuActive(item) ? "activeMenu" : ""
                    }`}
                    data-bs-toggle="collapse"
                    aria-expanded="true"
                    aria-controls={itemId}
                  >
                    <span>{item.heading}</span>
                    <span className="btn-open-sub" />
                  </a>
                  <div id={itemId} className="collapse">
                    <ul className="sub-nav-menu">
                      {item.links.map((subItem, j) => {
                        if (subItem.links) {
                          const subItemId = `mobile-menu-subcategory-${i}-${j}`;
                          return (
                            <li key={j}>
                              <>
                                <a
                                  href={`#${subItemId}`}
                                  className={`sub-nav-link collapsed ${
                                    isMenuActive(subItem) ? "activeMenu" : ""
                                  }`}
                                  data-bs-toggle="collapse"
                                  aria-expanded="true"
                                  aria-controls={subItemId}
                                >
                                  <span>{subItem.text}</span>
                                  <span className="btn-open-sub" />
                                </a>
                                <div id={subItemId} className="collapse">
                                  <ul className="sub-nav-menu sub-menu-level-2">
                                    {subItem.links.map((innerItem, k) => (
                                      <li key={k}>
                                        <Link
                                          href={innerItem.href}
                                          className={`sub-nav-link ${
                                            isMenuActive(innerItem)
                                              ? "activeMenu"
                                              : ""
                                          }`}
                                        >
                                          {innerItem.text || innerItem.heading}
                                          {innerItem.demoLabel && (
                                            <div className="demo-label">
                                              <span className="demo-new">
                                                New
                                              </span>
                                            </div>
                                          )}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </>
                            </li>
                          );
                        } else {
                          return (
                            <li key={j}>
                              <Link
                                href={subItem.href}
                                className={`sub-nav-link ${
                                  isMenuActive(subItem) ? "activeMenu" : ""
                                }`}
                              >
                                {subItem.text}
                                {subItem.demoLabel && (
                                  <div className="demo-label">
                                    <span className="demo-new">New</span>
                                  </div>
                                )}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="mb-other-content">
            <div className="d-flex group-icon">
              <Link href={`/wishlist`} className="site-nav-icon">
                <i className="icon icon-heart" />
                {t("product_description_p.title5")}
              </Link>
          {/*  
          
              <Link href={'SearchModal'} className="site-nav-icon">
           

                <i className="icon icon-search" />
                {t("product_description_p.title6")}
              </Link>
              */}
            </div>
            <div className="mb-notice">
              <Link href={`/contact-1`} className="text-need">
                {t("footer_text.title1")}
              </Link>
            </div>
            <ul className="mb-info">
              <li>
                <a
                  href="https://maps.app.goo.gl/rgUitJe2oREGspqx5"
                  target="_blank"
                  title="Get direction"
                  rel="noreferrer"
                >
                  Address: Josef-Schregel-Straße 68, <br /> 52349 Düren, Germany
                </a>
              </li>
              <li>
                <p>
                  Email:{" "}
                  <a href="mailto:info@kompakt-arbeitsschutz.de">
                    info@kompakt-arbeitsschutz.de
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Phone: <a href="tel:+49 2421 9590177">+49 2421 9590177</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="mb-bottom">
          <div className="bottom-bar-language">
            <div className="tf-languages">
              <LanguageSelect
                parentClassName={
                  "image-select center style-default type-languages"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
